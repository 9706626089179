.site-container {
  background: #ececec;
  background: url(https://images.pexels.com/photos/814499/pexels-photo-814499.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260);
  background-size: cover;
  padding: 1rem;
  display: flex;
  align-items: center;
  overflow: scroll;
  min-height: 100vh;
  height: 100%;
}

.site-container .ant-card-body {
  padding: 0.5rem;
}

@media only screen and (max-width: 768px) {
  .site-container {
    padding: 0.5rem;
  }

  .site-container .ant-card-body {
    padding: 0.5rem;
  }
}

.logo {
  font-family: "Vollkorn", serif;
  font-size: 24px;
  color: #fff;
  margin: 1rem auto;
  padding: 0 2rem;
}

.habit-prompt .ant-form-item-control {
  margin: auto;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}
