.dashboard {
  padding: 0.5rem;
}

.dashboard .ant-card-body {
  padding: 0.5rem;
}

@media only screen and (max-width: 768px) {
  .dashboard {
    padding: 0rem;
  }

  .dashboard .ant-card-body {
    padding: 0rem;
  }
}

/* To raise the sidebar hamburger */
.ant-layout-sider-zero-width-trigger {
  top: 22px;
}

.ant-col {
  padding: 1em 1em;
}
.label-col {
  display: flex;
  justify-content: space-between;
}

.cell {
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.05) !important;
}

.cell:hover {
  filter: drop-shadow(1px 2px 3px #000) !important;
}

.habit-row {
  padding: 0.2rem 0;
}

.habit-row:hover {
  background-color: #f0f5ff;
}
