.login-container {
  background: url("/landingpagescreen.jpg");
  background-size: cover;
  min-height: 100vh;
  padding: 120px 0;
  width: 100%;
}

.login-container:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1) 90%
  );
  width: 100%;
  height: 5em;
}

.firebaseui-container {
  margin: 0 0 !important;
}

.login-card {
  width: 100vw;
  height: 100%;
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.login-modal h1.ant-typography {
  font-size: 4rem;
}

@media only screen and (max-width: 768px) {
  .login-container {
    background-position: bottom right;
  }

  .login-modal {
    text-align: left;
    display: block;
  }

  .login-modal .headline {
    width: 100%;
  }

  .login-modal .firebaseui-container {
    margin-top: 4rem !important;
  }
}

.section {
  padding: 100px 0;
}

.section.explainer .title-area {
  max-width: 760px;
  margin: 0 auto 70px;
  display: block;
  padding: 0 15px;
  text-align: center;
}

.separator {
  color: #c5a47e;
  margin: 0 auto 20px;
  max-width: 240px;
  text-align: center;
  position: relative;
}

.separator-danger {
  color: #e78b90;
}

.separator:before,
.separator:after {
  display: block;
  width: 40%;
  content: " ";
  margin-top: 10px;
  border: 1px solid #c5a47e;
}

.separator:before {
  float: left;
}

.separator:after {
  float: right;
}

.section .title-area .description {
  font-size: 16px;
  color: #777777;
}

.section.feature {
  background: #000;
  color: #fff;
}

.feature h5 {
  color: #fff;
}

.feature .feature-item .icon {
  margin-bottom: 25px;
}

.feature .feature-item .icon i {
  font-size: 48px;
  color: #7d71d3;
}

.feature .feature-item .content h5 {
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 15px;
}

.feature .feature-item:not(:last-child) {
  margin-bottom: 80px;
}

@media (max-width: 768px) {
  .feature .feature-item {
    margin-bottom: 80px;
  }
}

.feature .app-screen {
  margin: 40px 0;
}

.feature .feature-content h2,
.feature .feature-content p {
  margin-bottom: 25px;
}

@media (max-width: 768px) {
  .feature .feature-content h2,
  .feature .feature-content p {
    text-align: center;
  }
}

@media (max-width: 768px) {
  .feature .testimonial {
    text-align: center;
  }
}

.feature .testimonial p {
  font-family: "Josefin Sans", sans-serif;
  margin-bottom: 10px;
  font-style: italic;
  color: #242424;
}

.feature .testimonial ul.meta li {
  font-size: 12px;
  margin-right: 10px;
}

.feature .testimonial ul.meta li img {
  height: 40px;
  width: 40px;
  border-radius: 100%;
}
